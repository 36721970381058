import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import {
  Configuration,
  DocumentControllerApi,
  DocumentControllerApiFp,
  EntityStatisticsControllerApi,
  EntityStatisticsControllerApiFp,
  ExportApi as ExportSCAIViewApi,
  SearchControllerApi,
} from '@scaiview/sdk-typescript'

import {
  ConceptApi,
  Configuration as SAMConfig,
  ExportApi,
  RetrievalApi,
  TerminologyApi,
} from '@scaibio/sam-sdk-typescript'
import { ControllerProvider } from '../api'

const APIRoute = (props) => {
  let { component: Component, ...rest } = props

  /* https://github.com/IdentityModel/oidc-client-js/wiki#user
        only redirect to a protected resource if user token is present and is not expired!
        This means, if the token expires the user can still view the current page but is
        redirected to the login page upon the next click.
      */

  const APIconfig = new Configuration({
    basePath: window.REACT_APP_API_URL,
  })

  const documentController = new DocumentControllerApi(APIconfig)
  const documentControllerFp = DocumentControllerApiFp(APIconfig)
  const entityStatisticsController = new EntityStatisticsControllerApi(
    APIconfig
  )
  const entityStatisticsControllerFp = EntityStatisticsControllerApiFp(
    APIconfig
  )
  const exportSCAIViewController = new ExportSCAIViewApi(APIconfig)
  const searchController = new SearchControllerApi(APIconfig)

  const samConfig = new SAMConfig({
    basePath: window.REACT_APP_SAM_URL,
  })
  const terminologyController = new TerminologyApi(samConfig)
  const conceptController = new ConceptApi(samConfig)
  const exportController = new ExportApi(samConfig)
  const retrievalController = new RetrievalApi(samConfig)
  return (
    <ControllerProvider
      value={{
        documentController,
        documentControllerFp,
        entityStatisticsController,
        entityStatisticsControllerFp,
        exportSCAIViewController,
        searchController,
        terminologyController,
        conceptController,
        exportController,
        retrievalController,
      }}
    >
      <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
    </ControllerProvider>
  )
}
export default withRouter(APIRoute)
