import React from 'react'
import { render } from 'react-dom'

import '@elastic/eui/dist/eui_theme_light.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'

import { Provider as ReduxProvider } from 'react-redux'
import { OidcProvider } from 'redux-oidc'

import store from './store'
import userManager from './utils/auth/userManager'
// import { makeServer } from './api/mirage/server'

// Log.logger = console;
// Log.level=Log.DEBUG
// @TODO: Here we need to set the redux state with the coprusID from the URL context!
const renderApp = () => {
  if (window.location.pathname === '/silentRenew') {
    userManager.signinSilentCallback().catch((err) => {
      console.error(err)
    })
  } else {
    const App = require('./App').default
    render(
      <ReduxProvider store={store}>
        <OidcProvider store={store} userManager={userManager}>
          <App />
        </OidcProvider>
      </ReduxProvider>,
      document.getElementById('root')
    )
  }
}

renderApp()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', renderApp)
  // Enable it for API mocking:
  // makeServer()
}
